import React from 'react'
import "./akredetion.css"

import ak001 from "./ImgAk/ak1.png"
import ak002 from "./ImgAk/ak2.png";

const Akredition = () => {
  return (
		<div className='akkkkk'>
			<div className='akak'>
 <br /><br /><br /><br /><br /><br /><br /><br />
				<div>

          <a href="https://docs.google.com/document/d/1hxa9qwJKgozNxVmqvFcvhNLigi4JSEW6/edit ">  <img className='ak010'  src={ak001} alt='' /></a>
		
				</div>

				<div>
          <a href="https://drive.google.com/drive/folders/1hhrDfIUHBRiwAXOimrQQLnZf6dShhCVo">	<img className='ak020' src={ak002} alt='' /></a>
				
				</div>
			</div>
		</div>
	);
}

export default Akredition
